






































































































































import { defineComponent, ref, reactive, computed, watch, Ref } from '@vue/composition-api'
import { riskKeywordGroup, riskKeywordGroupAdd, riskKeywordGroupDelete, riskKeywordGroupEdit } from '@/apis/index'
import { IRiskKeywordGroupResponse, IRiskKeywordGroupResponsePayload } from '@/types/apis'
import store from '@/store/index.js'
import { MessageBox } from 'element-ui'

type TableRow = {
  index: number
  sno: number
  keywordGroupName: string
  switch: number
  keywords: string[]
  state: 'empty' | 'exist' // 判斷資料庫資料是否已存在
}

const fetchTableData = async (): Promise<TableRow[]> => {
  // let data: IRiskKeywordGroupResponse | undefined
  try {
    await store.dispatch('actionRiskKeywordGroup')
  } catch (e: any) {
    return e
  }
  const data: IRiskKeywordGroupResponsePayload = store.state.riskKeywordGroup
  const tableData: TableRow[] = data.map((d, i) => {
    return {
      index: i,
      sno: d.sno,
      keywordGroupName: d.keyword_group_name,
      switch: d.switch,
      keywords: d.keywords,
      state: 'exist' // 資料庫有資料
    }
  })
  if (tableData.length < 5) {
    // 新增一個空的列
    tableData.push({
      index: tableData.length,
      sno: -1,
      keywordGroupName: '',
      switch: 0,
      keywords: [],
      state: 'empty'
    })
  }

  return tableData
}

// 取得異動的關鍵字
const getModifiedKeywords = (originKeywords: string[], currentKeywords: string[]) => {
  // 陣串轉物件
  const originKeywordMap: { [key: string]: boolean } = originKeywords.reduce((prev, current) => {
    return {
      ...prev,
      [current]: true
    }
  }, {})
  const currentKeywordMap: { [key: string]: boolean } = currentKeywords.reduce((prev, current) => {
    return {
      ...prev,
      [current]: true
    }
  }, {})

  const added = currentKeywords.filter(d => {
    return originKeywordMap[d] == undefined
  })
  const deleted = originKeywords.filter(d => {
    return currentKeywordMap[d] == undefined
  })
  return { added, deleted }
}

const isDuplicatedKeyword = ({ keyword, originTableData, editingRowData, editingRowIndex }: {
  keyword: string
  originTableData: TableRow[]
  editingRowData: TableRow
  editingRowIndex: number
}) => {
  const KeywordSet: Set<string> = new Set()
  const currentTableData: TableRow[] = Object.assign([], originTableData)
  currentTableData[editingRowIndex] = editingRowData

  currentTableData.forEach(d => {
    d.keywords.forEach(keyword => {
      KeywordSet.add(keyword)
    })
  })
  return KeywordSet.has(keyword)
}

export default defineComponent({
  setup(props, {root}) {
    const isManager = computed(() => store.getters.getterIsManager)
    const originTableData: Ref<TableRow[]> = ref([])
    const currentPage = ref(1)
    const isTableLoading = ref(true)    
    const isKeywordTyping = ref(false) // 是否正在輸入關鍵字
    const editingRowIndex = ref(-1) // 編輯中的列索引
    const editingRowData: Ref<TableRow | undefined> = ref()
    const typingKeyword = ref('')
    const keywordInputRef = ref() // dom

    // 取得資料
    isTableLoading.value = true
    fetchTableData()
      .then(d => {
        originTableData.value = d
        isTableLoading.value = false
      })
    
    return {
      isManager,
      originTableData,
      pageSize: 10,
      currentPage,
      isTableLoading,
      isKeywordTyping,
      editingRowIndex,
      editingRowData,
      typingKeyword,
      keywordInputRef,
      activeEditingRow (rowData: TableRow) {
        editingRowIndex.value = rowData.index
        editingRowData.value = JSON.parse(JSON.stringify(rowData))
      },
      cancelEditingRow () {
        editingRowIndex.value = -1
        editingRowData.value = undefined
      },
      async updateSwitch (value: number, rowData: TableRow) {
        if (rowData.state === 'empty') {
          // 尚未新增資料前不儲存
          return
        }
        isTableLoading.value = true
        await riskKeywordGroupEdit({
          sno: rowData.sno,
          action: 'switch',
          target: String(value)
        })
        // 重新更新資料
        originTableData.value = await fetchTableData()

        isTableLoading.value = false
      },
      async activeKeywordInput () {
        isKeywordTyping.value = true

        await root.$nextTick()

        ;(keywordInputRef.value as any).focus()
      },
      async addKeyword () {
        if (typingKeyword.value == '') {
          isKeywordTyping.value = false
          return
        }
        const _typingKeyword = typingKeyword.value
        typingKeyword.value = ''
        // 要先取消focus，否則後面的message box會再觸發blur而造成bug
        ;(keywordInputRef.value as any).blur()
        
        // 判斷新增關鍵字在現有所有的關鍵字中是否重履
        if (isDuplicatedKeyword({
          keyword: _typingKeyword,
          originTableData: originTableData.value,
          editingRowData: editingRowData.value!,
          editingRowIndex: editingRowIndex.value
        })) {
          const isContinue = await MessageBox.confirm('已新增過此詞彙，請確認是否要重複新增', {
            confirmButtonText: '確定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          if (isContinue != 'confirm') {
            return
          }
        }
        editingRowData.value!.keywords.push(_typingKeyword)
        isKeywordTyping.value = false
      },
      async deleteKeyword (index: number) {
        editingRowData.value!.keywords.splice(index, 1)
      },
      async saveTableData () {
        isTableLoading.value = true
        if (editingRowData.value!.state === 'empty') {
          // 增加整個群組
          await riskKeywordGroupAdd({
            keyword_group_name: editingRowData.value!.keywordGroupName,
            keywords: editingRowData.value!.keywords
          })           
        } else if (editingRowData.value!.state === 'exist') {
          const originRowData = originTableData.value[editingRowIndex.value]

          // keywordGroupName
          if (editingRowData.value!.keywordGroupName != originRowData.keywordGroupName) {
            await riskKeywordGroupEdit({
              sno: editingRowData.value!.sno,
              action: 'edit_keyword_group_name',
              target: editingRowData.value!.keywordGroupName
            })
          }
          // keywords
          const { added, deleted } = getModifiedKeywords(originRowData.keywords, editingRowData.value!.keywords)
          if (deleted.length) {
            await riskKeywordGroupEdit({
              sno: editingRowData.value!.sno,
              action: 'delete_keyword',
              target: deleted
            })
          }
          if (added.length) {
            await riskKeywordGroupEdit({
              sno: editingRowData.value!.sno,
              action: 'add_keyword',
              target: added
            })
          }
          // switch
          if (editingRowData.value!.switch != originRowData.switch) {
            await riskKeywordGroupEdit({
              sno: editingRowData.value!.sno,
              action: 'switch',
              target: String(editingRowData.value!.switch)
            })
          }
        }
        // 重新更新資料
        fetchTableData()
          .then(d => {
            originTableData.value = d
            editingRowData.value = undefined
            editingRowIndex.value = -1
            isKeywordTyping.value = false
            isTableLoading.value = false
          })
          .catch(e => {
            isTableLoading.value = false
          })
        
      }
    }
  },
})
